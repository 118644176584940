import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import clickTracking from "../../utils/tracking"
import OverviewThumbnail from "../global-components/thumbnails/overview-thumbnail"

const OverviewHeader = ({ overview, pathwayUri, percentComplete, readTimeCard }) => {

  return (
    <Link 
      onClick={() => {clickTracking("Steplist Overview Click", { key: pathwayUri })}}
      style={{ textDecoration: 'none'}}
      to={pathwayUri} 
      state={{ step: "overview" }}
    >
      <section className="overview-header">
        <div className="overview-header__thumbnail">
          {overview.stepImage ?
            <OverviewThumbnail
              backgroundImage={overview.stepImage.publicUrl}
              percentComplete={percentComplete}
            />
          :
            <StaticImage                       
              src="../../../content/assets/placeholder.png"
              alt="Compassus Placeholder Image"
              layout="fixed"
              quality="100"
              height={128}
              width={128}
              className="overview-header__thumbnail__image"
            />
          }
        </div>
        <div className="overview-header__text">
          <h3>Disease Overview</h3>
          <p>
            {overview.stepExcerpt}
          </p>
          <h6>Approx. read time: {readTimeCard ? readTimeCard : 5} Minutes</h6>
        </div>
      </section>
    </Link> 
  )
}

export default OverviewHeader