import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import PathwayProgressBar from "../global-components/pathwayprogressbar"
import CompletionCheckmark from "../global-components/completioncheckmark"

import clickTracking from "../../utils/tracking"
import OverviewHeader from "../../components/template-components/overviewheader"

import { useProgressContext } from "../../progressWrapper"
// import { useGetReadTime } from "../../utils/loginLog"

const StepListThumbnail = ({backgroundImage, percentComplete}) => {
  return (
    <div
      className="steplist-card__thumbnail__image"
      style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <PathwayProgressBar percentComplete={percentComplete} />
      <CompletionCheckmark percentComplete={percentComplete} />
    </div>
  )
}

const StepCard = ({step, pathwayUri, index, percentComplete, readTimeCard}) => {

    //takes step array and capitalizes the first letter, returning the rest of the string. 
    let stepUpper = step[0][0].toUpperCase() + step[0].substring(1)
    //Breaks apart step title on capitalized letter, adding spaces in between. (ex. cardiacDisease becomes Cardiac Disease)
    const stepTitle = stepUpper.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
    .replace("Right Meds Right Time", "Right Meds/Right Time")
    .replace("Safety And Autonomy", "Safety & Autonomy")
    .replace("Quality Of Life", "Quality of Life");

  return (
    <Link 
      onClick={() => {clickTracking("Steplist Step Click", { key: stepTitle })}}
      style={{ textDecoration: 'none'}}
      to={pathwayUri + step[1].fieldGroupName.replace(/_/g, "-")} 
      state={{ step: step[0] }}
      className="steplist-card-wrapper"
    >
      <div className="steplist-card">
        <div className="steplist-card__thumbnail">
          {step[1].stepImage ? 
            <StepListThumbnail
              backgroundImage={step[1].stepImage.publicUrl}
              percentComplete={percentComplete}
            />
          :
            <StaticImage                       
              src="../../../content/assets/placeholder.png"
              alt="Compassus Placeholder Image"
              layout="fixed"
              height={168}
              width={128}
              quality="100"
              className="steplist-card__thumbnail__image"
            />
          }
        </div>
        <div className="steplist-card__content-text">
          <h3>{index}. {stepTitle}</h3>
          <p>
            {step[1].stepExcerpt ? step[1].stepExcerpt : null }
          </p>
          <h6>Approx. read time: {readTimeCard ? readTimeCard : 5} Minutes</h6>
        </div>
      </div>
    </Link> 
  )
}

const StepList = ({ pathwayId, steps, pathwayUri }) => {

  const [progressData, progressLoading, setProgressLoading] = useProgressContext();

  const getStepProgress = ( step ) => {
    if (progressData) {
        const pathwayProgress = progressData.progress.filter(x => x.pathwayPostId == pathwayId)
        if(pathwayProgress[0]) {
          const fieldProgress = pathwayProgress[0].pathwayFields.filter(x => x.name == step);
          
          if( fieldProgress[0] !== undefined ) {
            return fieldProgress[0].pathwayFieldPercentComplete
          }
          else {
            return 0
          }
        }
    }
    return 0
  }

  // const { loadingData, readTime } = useGetReadTime(pathwayId);

  // const getReadTime = ( step ) => {

  //   let time = ""

  //   if (readTime && !loadingData) {
  //     Object.entries(readTime[0].time_to_reads).forEach((key) => {
  //       if (step + "_step" === key[0]) {
  //         let overTime = key[1].replace(" minutes","");
  //         if (parseInt(overTime) > 15) {
  //           time = "15 Minutes"
  //         } else if (parseInt(overTime) === 1) {
  //           time = "1 Minute"
  //         } else {
  //           time = key[1]
  //         }
  //       }
  //     })
  //   }

  //   return time;
  // }

  return (
    Object.entries(steps).map((step, index) => {
      return (
        step[0] !== "overview" ?
          <StepCard 
            key={index} 
            index={index} 
            step={step} 
            pathwayUri={pathwayUri}
            readTimeCard={step[1].stepReadTime}
            percentComplete={getStepProgress(step[0])}
          />
        :
        <OverviewHeader
            key={index} 
            pathwayUri={pathwayUri + "overview"}
            overview={step[1]}
            readTimeCard={step[1].stepReadTime}
            percentComplete={getStepProgress(step[0])}
        />
      )
    })

  )
}

export default StepList
