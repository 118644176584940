import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/global-components/navbar"

import PathwayHeader from "../components/template-components/pathwayheader"
import StepList from "../components/template-components/steplist"

const DiseasePathwayTemplate = ({ data }) => {
  const diseaseSteps = data.diseasePathway.pathwayFields;

  return (
    <>
      <Layout>
          <Seo title="Compassus Summary Page" />
          <PathwayHeader headerType={"interstitial"} step={"overview"} pathway={data.diseasePathway} />
          <StepList pathwayId={data.diseasePathway.databaseId} pathwayUri={data.diseasePathway.uri} steps={diseaseSteps}/>
      </Layout>
      <NavBar />
    </>
  )
}

export default DiseasePathwayTemplate

//Query for Disease Pathway Interstitial. This query will need to be refined based on authentication. Gets ID through context on gatsby.node page and returns targeted data including associated Modules.
export const pageQuery = graphql`
query DiseasePathwayById($id: String!) {
  diseasePathway: wpPathway(id: {eq: $id}) {
    id
    databaseId
    uri
    title
    pathwayCategories {
      nodes {
        name
      }
    }
    pathwayFields {
      overview {
        fieldGroupName
        stepExcerpt
        stepImage {
          altText
          sourceUrl
          publicUrl
          uri
        }
        stepBlock {
          ... on WpPathway_Pathwayfields_Overview_stepBlock {
            header
            modules {
              module {
                ... on WpModule {
                  id
                  databaseId
                  title
                  uri
                }
              }
            }
          }
          readTime
        }
        stepReadTime
      }
      rightMedsRightTime {
        fieldGroupName
        stepExcerpt
        stepImage {
          altText
          sourceUrl
          publicUrl
          uri
        }
        stepBlock {
          ... on WpPathway_Pathwayfields_RightMedsRightTime_stepBlock {
            header
            modules {
              module {
                ... on WpModule {
                  id
                  databaseId
                  title
                  uri
                }
              }
            }
          }
          readTime
        }
        stepReadTime
      }
      totalPainManagement {
        fieldGroupName
        stepExcerpt
        stepImage {
          altText
          sourceUrl
          publicUrl
          uri
        }
        stepBlock {
          ... on WpPathway_Pathwayfields_TotalPainManagement_stepBlock {
            header
            modules {
              module {
                ... on WpModule {
                  id
                  databaseId
                  title
                  uri
                }
              }
            }
          }
          readTime
        }
        stepReadTime
      }
      symptomManagement {
        fieldGroupName
        stepExcerpt
        stepImage {
          altText
          sourceUrl
          publicUrl
          uri
        }
        stepBlock {
          ... on WpPathway_Pathwayfields_SymptomManagement_stepBlock {
            header
            modules {
              module {
                ... on WpModule {
                  id
                  databaseId
                  title
                  uri
                }
              }
            }
          }
          readTime
        }
        stepReadTime
      }
      greatestConcern {
        fieldGroupName
        stepExcerpt
        stepImage {
          altText
          sourceUrl
          publicUrl
          uri
        }
        stepBlock {
          ... on WpPathway_Pathwayfields_GreatestConcern_stepBlock {
            header
            modules {
              module {
                ... on WpModule {
                  id
                  databaseId
                  title
                  uri
                }
              }
            }
          }
          readTime
        }
        stepReadTime
      }
      safetyAndAutonomy {
        fieldGroupName
        stepExcerpt
        stepImage {
          altText
          sourceUrl
          publicUrl
          uri
        }
        stepBlock {
          ... on WpPathway_Pathwayfields_SafetyAndAutonomy_stepBlock {
            header
            modules {
              module {
                ... on WpModule {
                  id
                  databaseId
                  title
                  uri
                }
              }
            }
          }
          readTime
        }
        stepReadTime
      }
      qualityOfLife {
        fieldGroupName
        stepExcerpt
        stepImage {
          altText
          sourceUrl
          publicUrl
          uri
        }
        stepBlock {
          ... on WpPathway_Pathwayfields_QualityOfLife_stepBlock {
            header
            modules {
              module {
                ... on WpModule {
                  id
                  databaseId
                  title
                  uri
                }
              }
            }
          }
          readTime
        }
        stepReadTime
      }
    }
  }
}

`
