import React from "react"
import CompletionCheckmark from "../completioncheckmark"
import PathwayProgressBar from "../pathwayprogressbar"

/**
 * Intended to be used as the thumbnail in header for pathway page (https://www.figma.com/file/eYDH6nIrMzilYTTtBcxgYW/Compassus-Pathways-3.0?node-id=1877%3A2485)
 * Appears as image on left of header
 * Props: accepts backgroundImage, complete (boolean to pass to CheckmarkIcon), and percentComplete (integer)
 * Makes use of the CheckmarkIcon to show completion
 */

const OverviewThumbnail = ({backgroundImage, percentComplete}) => {
  return (
    <div
      className="overview-header__thumbnail__image"
      style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <PathwayProgressBar percentComplete={percentComplete} />
      <CompletionCheckmark percentComplete={percentComplete} />
    </div>
  )
}

export default OverviewThumbnail
